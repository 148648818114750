@import "../../Common/Styles/EW_Variables";
@import "../../Core/Styles/Mixins";

.ew-input {

  min-height: 50px;

  .ew-input-core {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 100%;

    &.text-input {
      overflow: auto;
      text-overflow: fade;
      resize: none!important;
    }

    &, &:focus {
      border-color: $ew-transparent!important;
      outline-color: $ew-transparent!important;
      background-color: $ew-transparent!important;
    }
  }
}
