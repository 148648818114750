@import "../../Common/Styles/EW_Variables";
@import "../../Core/Styles/Mixins";

.ew-section-root {

  .ew-hidden-left {
    opacity: 0;
    @include use-transformed(translate(-100px, 0));
  }

  .ew-hidden-right {
    opacity: 0;
    @include use-transformed(translate(100px, 0));
  }

  .ew-hidden-top {
    opacity: 0;
    @include use-transformed(translate(0, -100px));
  }

  .ew-hidden-bottom {
    opacity: 0;
    @include use-transformed(translate(0, 100px));
  }

  &.isFinished {
    .ew-hidden-left, .ew-hidden-right, .ew-hidden-top, .ew-hidden-bottom {
      opacity: 1;
      @include use-transformed(translate(0px, 0px));
    }
  }
}