$ew-block-size: 100px;

$ew-half-block-size: 50px;

$ew-bg-blue: #377FF8;

$ew-bg-red: #F83778;

$ew-bg-grey-light: #F7F8FA;

$ew-color-white: #fff;

$ew-color-dark: #1b1b1b;

$ew-color-light: #838790;

$ew-transparent: rgba(0,0,0,0);
