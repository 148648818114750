@import "../../Core/Styles/Mixins";
@import "../../Common/Styles/EW_Variables";

@mixin use-spin ($speed) {
  animation: spin $speed infinite linear;
  -o-animation: spin $speed infinite linear;
  -moz-animation: spin $speed infinite linear;
  -webkit-animation: spin $speed infinite linear;

  @-moz-keyframes spin {
    from { @include use-transformed(rotate(0deg)); }
    to { @include use-transformed(rotate(359deg)); }
  }
  @-webkit-keyframes spin {
    from { @include use-transformed(rotate(0deg)); }
    to { @include use-transformed(rotate(359deg)); }
  }
  @keyframes spin {
    from { @include use-transformed(rotate(0deg)); }
    to { @include use-transformed(rotate(359deg)); }
  }
}

.ew-form {

  .ew-form-message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: $full-size;

    .ew-form-message-success-icon,
    .ew-form-message-error-icon {
      font-size: 100px;
      color: $brand-red;
      margin-bottom: 10px;
    }

    .ew-form-message-success-icon {
      color: $brand-blue;
    }
  }

  .ew-form-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255,255,255,.5);
    color: rgba(0,0,0,.2);
    font-size: 50px;

    .ew-form-overlay-spinner {
      background: rgba(255,255,255,.5);
    }
  }
}

