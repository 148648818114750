@import "../../Core/Styles/Mixins";
@import "../../Common/Styles/EW_Variables";

.ew-spinner {

  @mixin blob-animation-steps {
    from { @include use-transformed(scale(1, 1)); }
    50% { @include use-transformed(scale(1, .1)); }
    to { @include use-transformed(scale(1, 1)); }
  }

  @mixin use-blob-animation ($speed: 1s, $delay: 0, $ease: linear) {

    animation: blob-animation $speed infinite $delay ease-in;
    -o-animation: blob-animation $speed infinite $delay ease-in;
    -moz-animation: blob-animation $speed infinite $delay ease-in;
    -webkit-animation: blob-animation $speed infinite $delay ease-in;

    @-moz-keyframes blob-animation {
      @include blob-animation-steps();
    }
    @-webkit-keyframes blob-animation {
      @include blob-animation-steps();
    }
    @keyframes blob-animation {
      @include blob-animation-steps();
    }
  }

  .ew-spinner-item {
    width: 10px;
    height: 10px;
    margin: 0 5px;
    background: $grey-light;
    opacity: 0;

    &:first-child {
      @include use-blob-animation(900ms, 300ms);
    }

    &:nth-child(2n) {
      @include use-blob-animation(900ms, 600ms);
    }

    &:last-child {
      @include use-blob-animation(900ms, 900ms);
    }
  }

  &.isLoading {
    .ew-spinner-item {
      opacity: .4;
    }
  }
}