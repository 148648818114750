@import "../../Core/Styles/Mixins.scss";
@import "../../Common/Styles/EW_Variables.scss";

.ew-modal {

  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: $z-highest;
  overflow: auto;

  .ew-modal-content {
    opacity: 0;
    position: relative;
    @include use-transformed(scale(1.3));
    @include use-transformed-origin();
    @include use-transition($normal);
  }

  .ew-modal-overlay {
    position: fixed;
    opacity: 0;
    background: rgba(0, 0, 0, .5);
    @include use-transition($normal);
  }

  .ew-modal-close-btn {
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    z-index: $z-high + 1;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: flex-start;

  }

  &.isClosed {
    @include use-transformed(scale(0));
  }

  &.isOpened {
    .ew-modal-content,
    .ew-modal-overlay {
      opacity: 1;
      @include use-transformed(scale(1));
    }
  }
}
