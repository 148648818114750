@import "../../Common/Styles/EW_Variables";
@import "../../Core/Styles/Mixins";

.ew-app-footer {

    .ew-app-footer-nav-link {
      cursor: pointer;
      opacity: .7;
      font-weight: bold;
      @include use-transition();
      
  
      .ew-app-footer-nav-link-label {
        &:before {
          content: '';
          display: block;
          position: absolute;
          bottom: -1px;
          opacity: .3;
          left: $half-size;
          width: $zero-size;
          height: $half-size;
          background: $white;
          @include use-transition();
          white-space: nowrap;
        }
      }
  
      &.hover {
        opacity: .9;
      }
  
      &.isSelected {
        cursor: default;
  
        &, &:hover {
          opacity: 1;
        }
  
        .ew-app-footer-nav-link-label {
          &:before {
            left: calc(#{$zero-size} - 2px);
            width: calc(#{$full-size} + 4px);
            
          }
        }
      }
    }
}