@import "../../Common/Styles/EW_Variables";
@import "../../Core/Styles/Mixins";

@mixin use-pulse () {
  animation: pulse $slow infinite ease-out;
  -o-animation: pulse $slow infinite ease-out;
  -moz-animation: pulse $slow infinite ease-out;
  -webkit-animation: pulse $slow infinite ease-out;

  @-moz-keyframes pulse {
    from {
      opacity: 0;
      @include use-transformed(scale(.5));
    }
    50% {
      opacity: .1;
      @include use-transformed(scale(1));
    }
    to {
      opacity: .0;
      @include use-transformed(scale(1.2));
    }
  }
  @-webkit-keyframes pulse {
    from {
      opacity: 0;
      @include use-transformed(scale(.5));
    }
    50% {
      opacity: .1;
      @include use-transformed(scale(1));
    }
    to {
      opacity: .0;
      @include use-transformed(scale(1.2));
    }
  }
  @keyframes pulse {
    from {
      opacity: 0;
      @include use-transformed(scale(.5));
    }
    50% {
      opacity: .1;
      @include use-transformed(scale(1));
    }
    to {
      opacity: .0;
      @include use-transformed(scale(1.2));
    }
  }
}

.ew-file-input {
  border: 2px dashed $grey;
  background: rgba(247,248,250,0.8);
  min-height: calc(#{$ew-block-size} * 2);
  overflow: hidden;

  .ew-file-input-trigger {
    min-height: calc((#{$ew-block-size} * 2) - 4px);
  }

  .ew-file-input-file {
    max-width: $full-size;

    .ew-file-input-file-preview {
      border: 1px solid $grey-dark;
      max-width: $full-size;

      .ew-file-input-file-preview-label {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ew-file-input-file-preview-remove-btn {
        @include use-transformed(translate($half-size, -$half-size));
        @include use-transition();
        opacity: 0;
      }

      &:hover {
        .ew-file-input-file-preview-remove-btn {
          opacity: 1;
        }
      }
    }
  }

  &:before {
    content: '';
    display: none;
    top: 0;
    left:0;
    position: absolute;
    width: $full-size;
    height: $full-size;
    background: $grey-darker;
    @include use-pulse();
  }

  &.isActive {
    &:before {
      display: block;
    }
  }
}

