@import "../../Core/Styles/Mixins";
@import "../../Common/Styles/EW_Variables";

.ew-admin-dashboard {

  .ew-admin-dashboard-tab {
    @include use-box-shadow();
    overflow: hidden;

    .ew-admin-dashboard-tab-footer {
      background: $white;
      background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,.7) 50%, rgba(255,255,255,1) 100%);
      background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,.7) 50%, rgba(255,255,255,1) 100%);
      background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,.7) 50%, rgba(255,255,255,1) 100%);
    }
  }
}
