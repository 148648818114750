@import "../../Common/Styles/EW_Variables";
@import "../../Core/Styles/Mixins";

.ew-image-upload {
  .ew-image-upload-overlay {
    background: rgba(0,0,0,0);
    @include use-transition();

    .ew-image-upload-clear-button {
      cursor: pointer;
      opacity: 0;
      @include use-transition();
    }
  }

  &:hover {
    .ew-image-upload-overlay {
      background: rgba(0,0,0,.3);

      .ew-image-upload-clear-button {
        opacity: 1;
      }
    }
  }
}