@import "../../Core/Styles/Mixins.scss";
@import "../../Common/Styles/EW_Variables.scss";

@mixin use-animated-runner ($speed) {
  animation: animate-running $speed infinite linear;
  -o-animation: animate-running $speed infinite linear;
  -moz-animation: animate-running $speed infinite linear;
  -webkit-animation: animate-running $speed infinite linear;

  @-moz-keyframes animate-running {
    from { right: 0; }
    to { right: $full-size; }
  }
  @-webkit-keyframes animate-running {
    from { right: 0; }
    to { right: $full-size; }
  }
  @keyframes animate-running {
    from { right: 0; }
    to { right: $full-size; }
  }
}

@mixin use-pulse-animation ($speed: 1s) {
  animation: animate-pulse $speed infinite ease-in;
  -o-animation: animate-pulse $speed infinite ease-in;
  -moz-animation: animate-pulse $speed infinite ease-in;
  -webkit-animation: animate-pulse $speed infinite ease-in;

  @-moz-keyframes animate-pulse {
    from { opacity: .5; }
    50% { opacity: .9; }
    to { opacity: .5; }
  }
  @-webkit-keyframes animate-pulse {
    from { opacity: .5; }
    50% { opacity: .9; }
    to { opacity: .5; }
  }
  @keyframes animate-pulse {
    from { opacity: .5; }
    50% { opacity: .9; }
    to { opacity: .5; }
  }

}


.ew-loader {
  z-index: $z-highest + 1;

  .ew-loader-content {
    width: $full-size;
    height: $full-size;
    background: $white;

    .ew-loader-logo {
      width: 200px;
      height: 200px;
      @include use-pulse-animation();
    }

    //.ew-loader-spinner {
    //  background: $ew-bg-blue;
    //  width: $full-size;
    //  height: 10px;
    //  @include use-box-shadow();
    //
    //  .ew-loader-spinner-caret {
    //    background: $white;
    //    height: $full-size;
    //    width: 5px;
    //
    //    &:first-child {
    //      @include use-animated-runner(1s);
    //    }
    //
    //    &:nth-child(2n) {
    //      @include use-animated-runner(1.3s);
    //    }
    //
    //    &:last-child {
    //      @include use-animated-runner(0.7s);
    //    }
    //  }
    //}
  }
}
