@import "../../Common/Styles/EW_Variables";
@import "../../Core/Styles/Mixins";

.ew-app-header {

  .ew-app-header-nav-link {
    cursor: pointer;
    opacity: .7;
    font-weight: bold;
    @include use-transition();

    .ew-app-header-nav-link-label {
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: -1px;
        opacity: .3;
        left: $half-size;
        width: $zero-size;
        height: $half-size;
        background: $white;
        @include use-transition();
      }
    }

    &.hover {
      opacity: .9;
    }

    &.isSelected {
      cursor: default;

      &, &:hover {
        opacity: 1;
      }

      //.ew-app-header-nav-link-label {
      //  &:before {
      //    left: calc(#{$zero-size} - 2px);
      //    width: calc(#{$full-size} + 4px);
      //  }
      //}
    }
  }

  .ew-app-header-mobile-nav {

    .ew-app-header-mobile-nav-btn {
      @include use-transition();
      z-index: $z-high + 1;
      &:hover {
        background: $ew-bg-blue;
        color: $ew-color-white;
      }
    }

    &.isOpened {
      .ew-app-header-mobile-nav-btn {
        background: $ew-bg-blue;
        color: $ew-color-white;
      }
    }
  }

  .ew-app-header-mobile-nav-dropdown {
    @include use-transition();
    @include use-transformed(scale(0, 0));
    @include use-transformed-origin(50%, 0%);
    z-index: $z-high;
    background: rgba(0,0,0,.5);

    &.isOpened {
      @include use-transformed(scale(1, 1));
    }
  }
}
