@import "../../Core/Styles/Mixins";
@import "../../Common/Styles/EW_Variables";

.ew-admin-messages {

  .ew-admin-messages-item {

    .ew-admin-messages-item-header {
      @include use-box-shadow();
      @include use-transition();
    }

    .ew-admin-messages-item-body {
      @include use-box-shadow();
      @include use-transition();
      @include use-transformed(scale(1, 0));
      @include use-transformed-origin($half-size, 0);
    }

    &.isSelected {
      .ew-admin-messages-item-header {
        background: $ew-bg-blue;
        color: $ew-color-white;
      }

      .ew-admin-messages-item-body {
        @include use-transformed(scale(1, 1));
      }
    }
  }
}

