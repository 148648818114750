@import "./Mixins";

.font-uppercase {
  text-transform: uppercase;
}

.font-lowercase {
  text-transform: lowercase;
}

.size-cover {
  width: $full-size;
  height: $full-size;
}

.full-height {
  height: $full-size;
  min-height: $full-size;
}

.half-height {
  height: $half-size;
}

.full-width {
  width: $full-size;
}

.half-width {
  width: $half-size;
}

.pos-rel {
  position: relative;
}

.pos-abs {
  position: absolute;
}

.pos-fix {
  position: fixed;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

.display-inline {
  display: inline;
}

.display-none {
  display: none;
}

.display-flex {
  display: flex;
}
.display-inline-flex {
  display: inline-flex;
}

.display-flex,
.display-inline-flex {
  align-items: center;

  &.align-left {
    justify-content: flex-start;
  }
  &.align-center {
    justify-content: center;
  }
  &.align-right {
    justify-content: flex-end;
  }
}

.flex-direction-column {
  flex-direction: column;
}

//Font Size
@for $i from 0 through 56 {
  .font-size-#{$i} {
    font-size: #{$i}px;
  }

  .line-height-#{$i} {
    line-height: #{$i}px;
  }

  .letter-spacing-#{$i} {
    letter-spacing: #{$i * .01}px;
  }
}

//Animations
@for $i from 0 through 2 {
  .transition-duration-#{$i * 10} {

    @for $j from 0 through 20 {
      &.transition-delay-#{$j} {
        @include use-transition(#{$i * 500}ms, #{$j * 50}ms);
      }
    }
  }
}


// Indents
$margin-list: margin margin-top margin-bottom margin-left margin-right;
$padding-list: padding padding-top padding-bottom padding-left padding-right;

@for $i from 0 through 20 {

  @each $margin-property in $margin-list {
    $_i: $i * 10;

    .#{$margin-property}-#{$_i} {
      #{$margin-property}: #{$_i}px;
    }

    .#{$margin-property}-neg-#{$_i} {
      #{$margin-property}: -#{$_i}px;
    }

    .margin-v-#{$_i} {
      margin-top: #{$_i}px;
      margin-bottom: #{$_i}px;
    }

    .margin-h-#{$_i} {
      margin-left: #{$_i}px;
      margin-right: #{$_i}px;
    }

    .margin-#{$_i} {
      margin: #{$_i}px;
    }
  }

  @each $padding-property in $padding-list {
    $_i: $i * 10;

    .#{$padding-property}-#{$_i} {
      #{$padding-property}: #{$_i}px;
    }

    .padding-v-#{$_i} {
      padding-top: #{$_i}px;
      padding-bottom: #{$_i}px;
    }

    .padding-h-#{$_i} {
      padding-left: #{$_i}px;
      padding-right: #{$_i}px;
    }

    .padding-#{$_i} {
      padding: #{$_i}px;
    }
  }
}

// Positions
$positions-list: top right bottom left;

@for $i from 0 through 10 {
  $_i: $i * 50;

  @each $position-property in $positions-list {
    .#{$position-property}-#{$_i} {
      #{$position-property}: #{$_i}px;
    }

    .#{$position-property}-neg-#{$_i} {
      #{$position-property}: -#{$_i}px;
    }
  }
}


// Opacity
@for $i from 0 through 10 {
  .opacity-#{$i} {
    opacity: $i * .1;
  }
}

/* Breakpoint XS */
@media (max-width: 575px)
{
  .hidden-xs-down, .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down,
  .hidden-xs-up,
  .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
  {
    display: none !important;
  }

}

/* Breakpoint SM */
@media (min-width: 576px) and (max-width: 767px)
{
  .hidden-sm-down, .hidden-md-down, .hidden-lg-down, .hidden-xl-down,
  .hidden-xs-up, .hidden-sm-up,
  .hidden-unless-xs, .hidden-unless-md, .hidden-unless-lg, .hidden-unless-xl
  {
    display: none !important;
  }
}

/* Breakpoint MD */
@media (min-width: 768px) and (max-width: 991px)
{
  .hidden-md-down, .hidden-lg-down, .hidden-xl-down,
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up,
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-lg, .hidden-unless-xl
  {
    display: none !important;
  }
}

/* Breakpoint LG */
@media (min-width: 992px) and (max-width: 1199px)
{
  .hidden-lg-down, .hidden-xl-down,
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up,
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-xl
  {
    display: none !important;
  }
}

/* Breakpoint XL */
@media (min-width: 1200px)
{
  .hidden-xl-down,
  .hidden-xs-up, .hidden-sm-up, .hidden-md-up, .hidden-lg-up, .hidden-xl-up,
  .hidden-unless-xs, .hidden-unless-sm, .hidden-unless-md, .hidden-unless-lg
  {
    display: none !important;
  }
}

/* No select */

.noSelect {
  &, & * {
    @include use-no-select();
  }
}




