@import "../../../Common/Styles/EW_Variables";
@import "../../../Core/Styles/Mixins";

.ew-blog-constructor-content {

  .ew-blog-constructor-content-block {
    @include use-transition();
    &:hover {
      @include use-box-shadow();
    }
  }

  .ew-blog-constructor-content-control-panel {
    font-size: 20px;
    padding: 5px 10px;
    margin: 10px 0;
    border-left: 1px solid $grey-lighter;
    white-space: nowrap;
    display: inline-flex;

    .ew-blog-constructor-content-control-panel-button {
      height: 30px;
      padding: 0 5px;
      margin: 0 5px;
      opacity: .7;
      cursor: pointer;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      @include use-transformed(scale(.8));
      @include use-transition($fast);

      &:hover {
        opacity: 1;
      }

      &.isSelected {
        color: $brand-blue;
        @include use-transformed(scale(1));
      }
    }

    &:first-child {
      border-left: none;
    }
  }

  .ew-blog-constructor-content-block-add-content-panel {

    .ew-blog-constructor-content-block-add-content-panel-button {
      width: $ew-block-size;
      height: $ew-block-size;
      cursor: pointer;
      background: $white;
      @include use-transition();

      &:hover {
        background: $grey-lightest;
        @include use-box-shadow();
      }
    }
  }

  .ew-blog-constructor-content-add-block {
    background: $white;
    cursor: pointer;
    @include use-transition();
    @include use-box-shadow(1px, 1px, 5px, 0, rgba(0,0,0,.1));

    &:hover {
      background: $grey-lightest;
      @include use-box-shadow();
    }
  }

  .ew-blog-constructor-content-block-content-item-settings {
    white-space: nowrap;
    overflow-x: scroll;
  }

  .ew-blog-constructor-content-block-content-item {

    .ew-blog-constructor-content-block-content-item-input {
      overflow: hidden;

      &.text-input {

        .ew-blog-constructor-content-block-content-item-decoration {
          display: none;
        }

        &.use-font-weight-bold {
          .ew-input-core {
            font-weight: bold;
          }
        }

        &.use-font-weight-italic {
          .ew-input-core {
            font-style: italic;
          }
        }

        &.use-font-decoration-underline {
          .ew-input-core {
            text-decoration: underline;
          }
        }

        &.use-font-decoration-blockquote {
          .ew-input-core {
            padding-left: 100px;
          }

          .ew-blog-constructor-content-block-content-item-decoration {
            display: block;
          }
        }

        &.render-as-header-1 {
          .ew-input-core {
            font-size: 2.5rem;
            font-weight: 500;
            line-height: 1.2;
          }
        }

        &.render-as-header-2 {
          .ew-input-core {
            font-size: 2rem;
            font-weight: 500;
            line-height: 1.2;
          }
        }

        &.render-as-header-3 {
          .ew-input-core {
            font-size: 1.75rem;
            font-weight: 500;
            line-height: 1.2;
          }
        }
      }

      .ew-blog-constructor-content-block-content-item-decoration-logo {

        .ew-blog-constructor-content-block-content-item-decoration-logo-inner {
          @include use-transformed(translate(20px, 10px));
        }

        &:before {
          content: '';
          display: block;
          position: absolute;
          bottom: -150px;
          left: -150px;
          width: 300px;
          height: 300px;
          background: rgba(3, 80, 110, .7);
          @include use-transformed(rotate(45deg));
          @include use-transformed-origin();
          @include use-box-shadow();
        }
      }

      .ew-blog-constructor-content-block-content-item-decoration-banner {
        background: rgba(3, 80, 110, .7);
        color: $white;
        padding: 20px;
        margin-top: 10px;
        max-width: 50%;
        @include use-box-shadow();
      }
    }
  }
}
