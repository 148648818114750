@import "../../Core/Styles/Mixins";
@import "./EW_Variables";

body {
  color: $ew-color-dark;
  overflow-x: hidden;
}

::-webkit-scrollbar-button{
  display: none;
}
::-webkit-scrollbar-thumb{
  background-color: #CCC;
}
::-webkit-scrollbar-thumb:hover{
  background-color: #c1c1c1;
}
::-webkit-scrollbar-track{
  background-color: #fff;
}
::-webkit-scrollbar-track:hover{
  background-color: #fefefe;
}
::-webkit-scrollbar{
  width: 8px;
  height: 8px;
}

.ew-block-height {
  height: $ew-block-size;
}

.ew-block-width {
  width: $ew-block-size;
}

.ew-bg-blue {
  background-color: $ew-bg-blue;
}

.ew-bg-white {
  background-color: $white;
}

.ew-bg-gray {
  background-color: #F7F8FA;
}

.ew-bg-transparent {
  background-color: $ew-transparent;
}

.ew-bg-grey-light {
  background-color: $ew-bg-grey-light;
}

.ew-bg-footer{	
  background-color: #2F3846;
}

.ew-color-footer{
  color: rgba(255,255,255,0.6);
}

.ew-color-white {
  color: $white;
}

.ew-color-dark {
  color: $ew-color-dark;
}

.ew-color-light {
  color: $ew-color-light;
}

.ew-section {
  min-height: calc(100vh - #{$ew-block-size * 2});

  .ew-section-background {
    height: calc(100vh - #{$ew-block-size * 2});
  }
}

.ew-color-blue {
  color: #3FA4FF;
}

.ew-color-red {
  color: $ew-bg-red;
}

.ew-clickable {
  cursor: pointer;
}

@for $i from 0 through 10 {

  $props: width height;
  $ew-block-size-i: calc(#{$ew-block-size} * #{$i});
  $ew-block-size-i-and-quarter: calc(#{$ew-block-size-i} + #{$ew-block-size * .25});
  $ew-block-size-i-and-half: calc(#{$ew-block-size-i} + #{$ew-block-size * .5});
  $ew-block-size-i-and-three-quarters: calc(#{$ew-block-size-i} + #{$ew-block-size * .75});

  @each $prop in $props {
    .ew-block-#{$prop}-#{$i} {
      #{$prop}: $ew-block-size-i;
    }

    .ew-block-#{$prop}-#{$i}-and-quarter {
      #{$prop}: $ew-block-size-i-and-quarter;
    }

    .ew-block-#{$prop}-#{$i}-and-half {
      #{$prop}: $ew-block-size-i-and-half;
    }

    .ew-block-#{$prop}-#{$i}-and-three-quarters {
      #{$prop}: $ew-block-size-i-and-three-quarters;
    }
  }
}

.ew-full-section{
  min-height: calc(100vh - 200px);
}

.ew-two-three-heigth{
  height: 66.666%;
}

.ew-block-tab-heigth{
  height: 350px;
}

.ew-input-base {
  background: rgba(247,248,250,0.8);
  border-bottom: 3px solid darken($ew-bg-grey-light, 3%);
  margin: 0;

  .ew-input-placeholder {
    opacity: .4;
    left: 10px;
    @include use-transition();
    @include use-transformed(translate(0, 2px) scale(.7, .7));
    @include use-transformed-origin(0%, 0%);
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: $half-size;
    bottom: -2px;
    width: 0%;
    height: 2px;
    background: $ew-bg-blue;
    @include use-transition();
  }

  &.isFocused {
    &:after {
      width: $full-size;
      left: 0%;
    }
  }

  &.isEmpty {
    .ew-input-placeholder {
      opacity: .8;
      @include use-transformed(translate(0, 14px) scale(1, 1));
    }

    &.isFocused {
      .ew-input-placeholder {
        opacity: .6;
        @include use-transformed(translate(0, 3px) scale(.7, .7));
      }
    }
  }
}

.ew-first-class-header {
  font-size: 56px;

  @media (max-width: 991px) {
    font-size: 40px;
  }
}

.ew-second-class-header {
  font-size: 32px;

  @media (max-width: 991px) {
    font-size: 26px;
  }
}

