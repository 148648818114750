@import "../../Core/Styles/Mixins";
@import "../../Common/Styles/EW_Variables";

.ew-technologies-stack {

  .ew-technologies-stack-category {
    @include use-transition();
    border-bottom: 1px solid #EAECEF;
    opacity: .6;

    .ew-technologies-stack-category-item {
      .ew-technologies-stack-category-icon {
        @include use-transition();
        @include use-transformed(translate(0, 12px));
        height: calc(#{$ew-block-size * 1.5} - 50px);
        width: $ew-block-size * 1.5;
      }

      .ew-technologies-stack-category-label {
        @include use-transition();
        @include use-transformed(translate(0, -12px));
        opacity: 0;
      }
    }

    &:hover {
      opacity: 1;

      .ew-technologies-stack-category-item {
        .ew-technologies-stack-category-icon {
          @include use-transformed(translate(0, 0px));
        }

        .ew-technologies-stack-category-label {
          @include use-transformed(translate(0, -10px));
          opacity: 1;
        }
      }
    }
  }
}



