@import "../../Common/Styles/EW_Variables";
@import "../../Core/Styles/Mixins";
@import "./EW_AdminLoginForm/EW_AdminLoginForm";
@import "../EW_AdminBlogConstructor/EW_AdminBlogConstructor";

.ew-admin-panel {

  .ew-admin-panel-action-buttons {
    display: flex;
    flex-direction: column;
    bottom: 75px;

    .ew-admin-panel-action-button {
      height: 50px;
      margin-top: 5px;
      cursor: pointer;

      @include use-transformed(translate(5px, 0));
      @include use-transition();

      .ew-admin-panel-action-button-label {
        top: 0;
        right: 0;
        background: $white;
        height: $full-size;
        opacity: 0;
        padding: 0 60px 0 20px;
        border-bottom-left-radius: 25px;
        border-top-left-radius: 25px;
        white-space: nowrap;

        @include use-box-shadow();
        @include use-transformed(translate(100%, 0));
        @include use-transition();
      }

      .ew-admin-panel-action-button-icon {
        width: 50px;
        height: $full-size;
        background: $white;
        opacity: .5;
        cursor: pointer;
        border-bottom-left-radius: 25px;
        border-top-left-radius: 25px;
        @include use-box-shadow();
      }

      &:hover {
        opacity: 1;
        @include use-transformed(translate(0px, 0));

        .ew-admin-panel-action-button-label {
          opacity: .8;
          @include use-transformed(translate(0%, 0));
        }
      }
    }
  }
}
