@import "../../../Common/Styles/EW_Variables";
@import "../../../Core/Styles/Mixins";

.ew-admin-blog-constructor-tags {

 .ew-admin-blog-constructor-tags-item {
   height: 50px;
   max-width: 300px;
   background: $ew-bg-blue;
   color: $white;
   opacity: .7;
   padding: 0 10px;
   white-space: nowrap;
   margin-right: 10px;
   margin-bottom: 10px;
   vertical-align: top;

   @include use-box-shadow();
   @include use-transition();

   &:hover {
     opacity: 1;
   }

   .ew-admin-blog-constructor-tags-item-icon {
     cursor: pointer;
   }
 }
}
