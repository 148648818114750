@import "../../Core/Styles/Mixins";
@import "../../Common/Styles/EW_Variables";

.ew-about-page {

  .ew-about-page-first-section {

    .ew-about-page-first-section-bg {
      height: calc(100vh - #{$ew-block-size * 2});
    }
  }

  .ew-about-page-second-section-item {
    vertical-align: top;
  }

}
