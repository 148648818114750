@import "../../Core/Styles/Mixins";
@import "../../Common/Styles/EW_Variables";

.ew-blog-post-page {

  .ew-blog-post-page-body {

    .ew-blog-post-page-body-item {
      font-size: 14px;

      .ew-blog-post-page-body-item-decoration {
        justify-content: flex-end;
        padding: 10px;
      }

      .ew-image-block {
        min-height: calc(40vh);
      }

      .ew-font-weight-bold {
        font-weight: bold;
      }

      .ew-font-weight-italic {
        font-style: italic;
      }

      .render-as-header-1 {
        font-size: 2.5rem;
        font-weight: 500;
        line-height: 1.2;
      }

      .render-as-header-2 {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.2;
      }

      .render-as-header-3 {
        font-size: 1.75rem;
        font-weight: 500;
        line-height: 1.2;
      }

      &.ew-font-decoration-underline {
        text-decoration: underline;
      }

      &.ew-font-decoration-blockquote {
        padding-left: $ew-block-size;

        .ew-blog-post-page-body-item-decoration {
          display: flex;
          width: $ew-block-size;
          height: $full-size;
        }
      }
    }
  }
}

