// Colors
$white: #fff;
$grey-lightest: #f7f7f7;
$grey-lighter: #bbb;
$grey-light: #999;
$grey: #777;
$grey-dark: #555;
$grey-darker: #333;
$grey-darkest: #111;
$black: #000;
$transparent: rgba(0,0,0,0);

$brand-blue: #2baadf;
$brand-blue-transparent: rgba(43, 170, 223, .2);
$brand-green: #55aa16;
$brand-red: #aa1655;

// Z-index
$z-high: 500;
$z-higher: 1000;
$z-highest: 5000;

// Dimensions
$full-size: 100%;
$half-size: 50%;
$quarter-size: 25%;
$zero-size: 0%;

$min: 320px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// Animation
$immediate: 0ms;
$fast: 100ms;
$normal: 500ms;
$slow: 1000ms;
$very-slow: 5000ms;

// Borders
$standard-border: 1px solid rgba(169, 169, 169, 1);

// Globals
$phi: 1.618;
