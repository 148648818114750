@import "../../Core/Styles/Mixins";
@import "../../Common/Styles/EW_Variables";

.ew-home-page {

  .ew-home-page-modal-form-content {
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;

    .ew-home-page-modal-form-message {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .ew-home-page-modal-form-success-icon,
      .ew-home-page-modal-form-error-icon {
        font-size: 100px;
        color: $brand-red;
        margin-bottom: 10px;
      }

      .ew-home-page-modal-form-success-icon {
        color: $brand-blue;
      }
    }

    @media (max-width: $md) {
      width: 100vw;
    }
  }

}

