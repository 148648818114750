@import "~bootstrap/dist/css/bootstrap.min.css";
@import "../Core/Styles/Common";
@import "../Common/Styles/EW_Variables";
@import "../Common/Styles/EW_ThemeLight";
@import "../Routes/Routes";
@import "../Components/Components";
@import "./EW_AppHeader/EW_AppHeader";
@import "./EW_AppFooter/EW_AppFooter";
@import "./EW_TechnologiesStack/EW_TechnologiesStack";
@import "./EW_AdminPanel/EW_AdminPanel.scss";

body {
  font-size: 12px;

  &.non-scrollable {
    overflow: hidden!important;
  }
}

.ew-main {
  overflow-x: hidden!important;
  min-height: calc(100vh - #{$ew-block-size * 3});

  .ew-main-view {
    min-height: inherit;
  }
}
