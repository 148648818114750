@import "../../Core/Styles/Mixins";
@import "../../Common/Styles/EW_Variables";

.ew-button {
  border: none!important;
  padding: 0!important;
  background: $ew-transparent;

  &, &:focus, &:active {
    outline: none!important;
  }

  .square-button,
  .round-button {
    vertical-align: top;
    white-space: nowrap;
    background: $ew-bg-blue;
    height: $ew-half-block-size;
    border: 1px solid $white;
    @include use-transition();

    &:hover {
      background-color: darken($ew-bg-blue, 5%);
    }

    &.red-button {
      background: $ew-bg-red;
      &:hover {
        background-color: darken($ew-bg-red, 5%);
      }
    }

    &.white-button {
      background: $white;
      color: $grey-light;
      border: 1px solid $grey-light;

      &:hover {
        background-color: darken($white, 5%);
      }
    }
  }

  .as-text-button {
    background:none;
    border:none;
    cursor: pointer;
  }

  .square-button {
    .square-button-icon {
      background: rgba(255, 255, 255, .2);
      width: $ew-half-block-size;
      @include use-transition();
    }

    &:hover {
      .square-button-icon {
        background: rgba(255, 255, 255, .4);
      }
    }
  }

  .round-button {
    border-radius: calc(#{$ew-half-block-size} * .5);
  }

  .ew-button-interactive {
    overflow: hidden;

    .ew-button-animation {
      width: 0;
      height: 0;
      background: rgba(0,0,0, .5);
      border-radius: 50px;
      opacity: 0;
      @include use-transformed-origin();
    }
  }

  

  &.isDisabled {
    cursor: not-allowed;
    opacity: .7;

    &, &:hover {
      .square-button,
      .round-button {
        background: $ew-bg-blue!important;

        &.red-button {
          background: $ew-bg-red!important;
        }

        .square-button-icon {
          background: rgba(255, 255, 255, .2)!important;
        }
      }
    }
  }
}
