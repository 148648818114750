@import "../../Common/Styles/EW_Variables";
@import "../../Core/Styles/Mixins";

.ew-background-image {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @include use-transition();
  @include use-transformed(scale(1.1));
  opacity: 0;

  &.size-contain {
    background-size: contain;
  }

  &.isActive {
    @include use-transformed(scale(1));
    opacity: 1;
  }

}
