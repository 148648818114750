@import "../../Core/Styles/Mixins";
@import "../../Common/Styles/EW_Variables";

.ew-admin-pages-editor {

  .ew-admin-pages-editor-scrollable-block {
    overflow-x: auto;
    white-space: nowrap;

    .ew-admin-pages-editor-scrollable-block-item {
      background: $white;
      color: $ew-color-dark;
      @include use-box-shadow();
      @include use-transition();

      &:hover {
        background: $ew-bg-grey-light;
      }

      &.isSelected {
        &, &:hover {
          background: $ew-bg-blue;
          color: $ew-color-white;
        }
      }
    }
  }
}
