@import "../../../Core/Styles/Mixins";

.ew-admin-login-form {
  width: 50vw;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: $md) {
    width: 100vw;
  }
}