@import "../../Common/Styles/EW_Variables";
@import "../../Core/Styles/Mixins";

.ew-growl {
  @include use-transition();
  @include use-transformed-origin($half-size, 0%);
  @include use-transformed(translate(-$half-size, -50px) scale(1, 0));
  @include use-box-shadow();
  z-index: $z-highest + 10;
  width: $ew-block-size * 4;
  max-width: calc(#{$full-size} - 20px);
  left: $half-size;
  top: 20px;

  .ew-growl-close-btn {
    top: 10px;
    right: 10px;
  }

  &.isOpened {
    @include use-transformed(translate(-$half-size, 0) scale(1, 1));
  }
}

